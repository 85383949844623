import { FC } from 'react'
import { message } from 'antd'
import { enableMapSet } from 'immer'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import {
  ProdWarningModal,
  UserProvider,
  defaultQueryClientConfig,
  initLocalization,
  ThirdGenToggleProvider,
  ErrorBoundary,
} from '@signifyd/components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { splitIoStagingKey } from '@signifyd/sig-keys'
import { SplitFactoryProvider } from '@splitsoftware/splitio-react'
import { NAV_BAR_HEIGHT, SPACE_MD } from 'core/constants'
import locales from 'locales'
import AppContainer from './AppContainer'

message.config({
  top: NAV_BAR_HEIGHT + SPACE_MD,
  maxCount: 1,
})

enableMapSet()

initLocalization(locales)

const queryClient = new QueryClient(defaultQueryClientConfig)

const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: splitIoStagingKey,
    key: 'user',
  },
}

const App: FC = () => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <UserProvider>
              <SplitFactoryProvider config={sdkConfig}>
                <ThirdGenToggleProvider>
                  <AppContainer />
                </ThirdGenToggleProvider>
              </SplitFactoryProvider>
            </UserProvider>
            <ProdWarningModal />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryParamProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

export default App
