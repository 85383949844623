import { GET_RULES_ORDER_BY, CHECKPOINT } from '@signifyd/http'
import {
  DecodedValueMap,
  NumberParam,
  SetQuery,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { createEnumParam } from '@signifyd/utils'

export const dashboardQueryParams = {
  publishedOrderBy: withDefault(
    createEnumParam(GET_RULES_ORDER_BY),
    GET_RULES_ORDER_BY.SORT_RANK
  ),
  othersOrderBy: withDefault(
    createEnumParam(GET_RULES_ORDER_BY),
    GET_RULES_ORDER_BY.SORT_CREATED_AT
  ),
  teamId: NumberParam,
  showNewRules: StringParam,
  checkpoint: createEnumParam(CHECKPOINT),
  editedPolicyId: NumberParam,
}

export type DashboardPageFilters = DecodedValueMap<typeof dashboardQueryParams>

export type UseDashboardPageFilters = [
  DecodedValueMap<typeof dashboardQueryParams>,
  SetQuery<typeof dashboardQueryParams>,
]

export const useDashboardPageFilters = (): UseDashboardPageFilters => {
  const [query, setQuery] = useQueryParams(dashboardQueryParams)

  return [query, setQuery]
}
