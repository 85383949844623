import { FC } from 'react'
import { Spin, Layout, Typography, Flex } from 'antd'
import { useTranslation, Trans } from 'react-i18next'
import { ListsIcon, Text } from '@signifyd/components'
import StretchedNoResults from 'core/components/StretchedNoResults'
import { ThirdGenNavBar } from 'core/containers/AppNavBar'
import CreateListButtonWithModal from 'pages/ListPage/containers/CreateListButtonWithModal'
import ListFilters from 'pages/ListPage/containers/ListFilters'
import { ListGrids, ListLoadingGrid } from 'pages/ListPage/containers/ListGrids'
import { useGetLists } from 'pages/ListPage/useGetLists'
import { Filters } from 'stores/predictionList'
import { useListPageQueryParams } from 'pages/ListPage/useListPageQueryParams'
import TeamSelect from 'core/components/TeamSelect'
import useUserTeams from 'core/hooks/useUserTeams'
import TestTeamBanner from 'core/components/TestTeamBanner'
import styles from './ListPageContainer.less'

const { Content } = Layout

interface Props {
  filters: Filters
}

const ListPageContainerThirdGen: FC<Props> = ({ filters }) => {
  const { t } = useTranslation()

  const [, setQuery] = useListPageQueryParams()
  const { liveTeams } = useUserTeams()

  const { data, isLoading, fetchStatus } = useGetLists(filters)

  let pageContent

  if (!data || isLoading) {
    pageContent = <ListLoadingGrid />
  } else if (data.predictionLists.length === 0) {
    // Show no lists page if the team doesn't have any lists at all.
    pageContent = (
      <StretchedNoResults
        icon={ListsIcon}
        title={t('listPage.placeholderTitle')}
        description={
          <Trans
            i18nKey="listPage.placeholderDescription"
            components={{ bold: <Text weight="semibold" /> }}
          />
        }
      />
    )
  } else if (data.filteredPredictionLists.length === 0) {
    // Show no results page when the GET call finishes but no lists returned.
    pageContent = (
      <StretchedNoResults
        title={t('listPage.noResultsTitle')}
        description={t('listPage.noResultsDescription')}
      />
    )
  } else {
    // Show real lists when the GET call finishes and there are lists returned.
    pageContent = <ListGrids predictionLists={data.filteredPredictionLists} />
  }

  const { listPageSortByFilter, listPageTypeFilter, listPageExpirationFilter } =
    filters

  return (
    <Layout style={{ height: '100vh' }}>
      <ThirdGenNavBar />
      <Content>
        <TestTeamBanner teamId={Number(filters.teamId)} />
        <Flex className={styles.header} justify="space-between">
          <Typography.Title>{t('listCommon.appName')}</Typography.Title>
          <div className={styles.headerExtra}>
            {!!liveTeams?.length && (
              <TeamSelect
                className={styles.teamSelect}
                label={t('common.teamSelect.label')}
                selectedTeamId={filters.teamId}
                onChange={(teamId) => {
                  setQuery({ teamId: +teamId })
                }}
              />
            )}
            <CreateListButtonWithModal filters={filters} />
          </div>
        </Flex>
        <div data-test-id="listPage" className={styles.content}>
          {!!data?.predictionLists && (
            <ListFilters
              predictionLists={data.predictionLists}
              predictionListSortBy={listPageSortByFilter}
              predictionListType={listPageTypeFilter}
              predictionListExpiration={listPageExpirationFilter}
              onChangeSortBy={(predictionListSortBy) =>
                setQuery({ sortBy: predictionListSortBy })
              }
              onChangeType={(predictionListType) =>
                setQuery({ type: predictionListType })
              }
              onChangeExpiration={(predictionListExpiration) =>
                setQuery({
                  hasExpiration: predictionListExpiration,
                })
              }
            />
          )}
        </div>
        {fetchStatus === 'fetching' && data ? (
          <Spin>
            <Content className={styles.content}>{pageContent}</Content>
          </Spin>
        ) : (
          <Content className={styles.content}>{pageContent}</Content>
        )}
      </Content>
    </Layout>
  )
}

export default ListPageContainerThirdGen
