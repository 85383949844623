import { CHECKPOINT } from '@signifyd/http'
import { useState, useEffect } from 'react'
import useSelectedTeamCheckpoints from 'pages/DashboardPage/queries/useSelectedTeamCheckpoints'
import { useDashboardPageFilters } from 'pages/DashboardPage/hooks/useDashboardPageFilters'

// TODO FET-1831 https://signifyd.atlassian.net/browse/FET-1831
// Definitely duplicate logic in here with the getFirstCheckpoint thing
/**
 * Sets the initially selected checkpoint to the query string's checkpoint param (with fallback for when it isn't present)
 */
const useSelectedCheckpoint = (): readonly [
  CHECKPOINT,
  React.Dispatch<React.SetStateAction<CHECKPOINT>>,
  () => void,
] => {
  const [{ checkpoint }] = useDashboardPageFilters()
  const { data: teamCheckpoints } = useSelectedTeamCheckpoints()

  const defaultCheckpoint =
    checkpoint ?? teamCheckpoints?.[0] ?? CHECKPOINT.CHECKOUT

  const [selectedCheckpoint, setSelectedCheckpoint] =
    useState<CHECKPOINT>(defaultCheckpoint)

  useEffect(() => {
    setSelectedCheckpoint(defaultCheckpoint)
  }, [defaultCheckpoint])

  const refreshSelectedCheckpoint = (): void => {
    setSelectedCheckpoint(defaultCheckpoint)
  }

  return [
    selectedCheckpoint,
    setSelectedCheckpoint,
    refreshSelectedCheckpoint,
  ] as const
}

export default useSelectedCheckpoint
