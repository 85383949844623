import React from 'react'
import ReactDOM, { render } from 'react-dom'
import { StoreProvider } from 'easy-peasy'
import { AuthProvider, PageSpinner } from '@signifyd/components'
import App from 'App'
import store from 'stores'
import 'antd/dist/reset.css'
import '@signifyd/ant/dist/index.less'

import './index.less'

if (process.env.NODE_ENV === 'development') {
  import('@axe-core/react').then((axe) => {
    axe.default(React, ReactDOM, 1000, {}, '#root')
  })
}

render(
  <AuthProvider LoadingComponent={<PageSpinner />} usingLegacyRouter>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </AuthProvider>,
  document.getElementById('root')
)
