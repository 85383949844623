import { ReactNode } from 'react'
import moment, { Moment } from 'moment-timezone'
import { AxisTickProps } from '@nivo/axes'
import { DatumValue, Serie } from '@nivo/line'
import {
  colorGold,
  colorMagenta,
  colorMarengo,
  colorMeadow,
  colorSlate,
  colorWhite,
  colorYonder,
} from '@signifyd/colors'
import {
  AggregatePolicyReportResponse,
  CHECKPOINT,
  CHECKPOINT_ACTION,
  CHECKPOINT_ACTIONS,
} from '@signifyd/http'
import { round } from 'lodash'
import {
  POLICY_REPORTING_TYPE,
  TIMEFRAME,
} from 'core/components/PolicyReporting/PolicyReporting.utils'
import { EnhancedPolicies } from 'pages/DashboardPage/containers/DashboardContainer/queries/useGetDashboardContainerData'
import { POLICY_GROUP_TYPE } from 'pages/DashboardPage/DashboardPage.types'

export enum POLICY_REPORTING_LINE {
  accept = 'Accept',
  reject = 'Reject',
  hold = 'Hold',
  credit = 'Credit',
  none = 'None',
}

export const reportingLinesByCheckpoint = {
  [CHECKPOINT.CHECKOUT]: [
    POLICY_REPORTING_LINE.accept,
    POLICY_REPORTING_LINE.reject,
    POLICY_REPORTING_LINE.hold,
  ],
  [CHECKPOINT.RETURN]: [
    POLICY_REPORTING_LINE.accept,
    POLICY_REPORTING_LINE.reject,
    POLICY_REPORTING_LINE.hold,
    POLICY_REPORTING_LINE.credit,
  ],
}

export const policyReportingLineStyles = {
  [POLICY_REPORTING_LINE.accept]: {
    strokeWidth: 1.5,
    stroke: colorMeadow,
  },
  [POLICY_REPORTING_LINE.reject]: {
    strokeDasharray: '4 4',
    strokeWidth: 1.5,
    stroke: colorMagenta,
  },
  [POLICY_REPORTING_LINE.hold]: {
    strokeDasharray: '2 4 6 2 4 6',
    strokeWidth: 1.5,
    stroke: colorSlate,
  },
  [POLICY_REPORTING_LINE.credit]: {
    strokeDasharray: '8 8 16 8 2 2 2 8',
    strokeWidth: 1.5,
    stroke: colorGold,
  },
  [POLICY_REPORTING_LINE.none]: {
    strokeWidth: 1.5,
    stroke: colorMarengo,
  },
}

export const isValidPolicyReportingLine = (
  id: string | number
): id is POLICY_REPORTING_LINE =>
  Object.values(POLICY_REPORTING_LINE).includes(id as POLICY_REPORTING_LINE)

const sortDates = (
  a: {
    x: string
    y: number
  },
  b: {
    x: string
    y: number
  }
): number => new Date(a.x).getTime() - new Date(b.x).getTime()

const hasPolicyAction = (
  policies: EnhancedPolicies,
  action: CHECKPOINT_ACTION
): boolean => {
  return (
    policies.publishedPolicies.some(
      (policy) => policy.ruleOutcome.ruleRecommendedAction === action
    ) ||
    policies.otherPolicies.some(
      (policy) => policy.ruleOutcome.ruleRecommendedAction === action
    )
  )
}

export const convertPolicyHistorytoChartData = (
  data: AggregatePolicyReportResponse,
  type: 'Count' | 'Gmv',
  policies: EnhancedPolicies,
  policyGroupType: POLICY_GROUP_TYPE
): Array<Serie> => {
  const hasPolicies =
    policyGroupType === POLICY_GROUP_TYPE.published
      ? !!policies.publishedPolicies.length
      : !!policies.otherPolicies.length

  if (!hasPolicies) {
    return [
      {
        id: POLICY_REPORTING_LINE.none,
        data: data.acceptHistory
          .map((item) => ({
            x: item.timestamp,
            y: 0,
          }))
          .sort(sortDates),
      },
    ]
  }

  const chartData: Array<Serie> = []

  if (hasPolicyAction(policies, CHECKPOINT_ACTIONS.CREDIT)) {
    chartData.push({
      id: POLICY_REPORTING_LINE.credit,
      data: data.creditHistory
        .map((item) => ({
          x: item.timestamp,
          y: item[`hit${type}`],
        }))
        .sort(sortDates),
    })
  }
  if (hasPolicyAction(policies, CHECKPOINT_ACTIONS.HOLD)) {
    chartData.push({
      id: POLICY_REPORTING_LINE.hold,
      data: data.holdHistory
        .map((item) => ({
          x: item.timestamp,
          y: item[`hit${type}`],
        }))
        .sort(sortDates),
    })
  }
  if (hasPolicyAction(policies, CHECKPOINT_ACTIONS.REJECT)) {
    chartData.push({
      id: POLICY_REPORTING_LINE.reject,
      data: data.rejectHistory
        .map((item) => ({
          x: item.timestamp,
          y: item[`hit${type}`],
        }))
        .sort(sortDates),
    })
  }
  if (hasPolicyAction(policies, CHECKPOINT_ACTIONS.ACCEPT)) {
    chartData.push({
      id: POLICY_REPORTING_LINE.accept,
      data: data.acceptHistory
        .map((item) => ({
          x: item.timestamp,
          y: item[`hit${type}`],
        }))
        .sort(sortDates),
    })
  }

  return chartData
}

export const getTickValues = (
  data: Array<Serie>,
  timeframe: TIMEFRAME
): Array<DatumValue | null | undefined> => {
  switch (timeframe) {
    case TIMEFRAME['24H']:
      return data[0].data
        .filter((_, index) => {
          return index % 8 === 0
        })
        .map((datum) => datum.x)
    case TIMEFRAME['30D']:
    case TIMEFRAME['7D']:
    case TIMEFRAME['90D']:
    case TIMEFRAME['180D']:
    case TIMEFRAME['365D']:
      return data[0].data.map((datum) => datum.x)
    default:
      return []
  }
}

export const formatAxisDate = (
  date: Moment,
  timeframe: TIMEFRAME,
  isFirstDate = false,
  isLastDate = false,
  xOffset = 0
): JSX.Element => {
  const now = moment(moment.now()).utc()
  let endDate = moment(date).utc()
  let doesMonthChange: boolean
  let formattedDate: string

  switch (timeframe) {
    case TIMEFRAME['24H']:
      if (isFirstDate || isLastDate) {
        return (
          <>
            <tspan x={xOffset} dy="0em">
              {date.format('MMM. D')}
            </tspan>
            <tspan x={xOffset} dy="1.2em">
              {date.format('h:mmA z')}
            </tspan>
          </>
        )
      }

      return <>{date.format('h:mm - h:59A z')}</>
    case TIMEFRAME['7D']:
      return <>{date.format('MMM. D')}</>
    case TIMEFRAME['30D']:
      if (isFirstDate || isLastDate || date.date() === 1) {
        return (
          <>
            <tspan x={xOffset} dy="0em">
              {date.format('MMM. D,')}
            </tspan>
            <tspan x={xOffset} dy="1.2em">
              {date.format("[']YY")}
            </tspan>
          </>
        )
      }

      return <>{date.format('D')}</>
    case TIMEFRAME['90D']:
      // tooltip for 90 day timeframe should display 1 week range, i.e. Mar. 1 - 7, 8 - 14, etc.
      endDate = endDate.add(6, 'days')

      if (endDate.isSameOrAfter(now)) {
        endDate = now
      }

      doesMonthChange = date.month() !== endDate.month()
      formattedDate = `${date.format('MMM. D')} - ${endDate.format(
        ` ${doesMonthChange ? 'MMM. D' : 'D'}`
      )}`

      if (isFirstDate || isLastDate) {
        return (
          <>
            <tspan x={xOffset} dy="0">
              {formattedDate},
            </tspan>
            <tspan x={xOffset} dy="1.2em">
              {endDate.format("'YY")}
            </tspan>
          </>
        )
      }

      return <>{formattedDate}</>
    case TIMEFRAME['180D']:
      // 180 day timeframe should display 2 week range
      endDate = endDate.add(13, 'days')

      if (endDate.isSameOrAfter(now)) {
        endDate = now
      }

      doesMonthChange = date.month() !== endDate.month()
      formattedDate = `${date.format('MMM. D')} - ${endDate.format(
        ` ${doesMonthChange ? 'MMM. D' : 'D'}`
      )}`

      if (isFirstDate || isLastDate) {
        return (
          <>
            <tspan x={xOffset} dy="0">
              {formattedDate},
            </tspan>
            <tspan x={xOffset} dy="1.2em">
              {endDate.format("'YY")}
            </tspan>
          </>
        )
      }

      return <>{formattedDate}</>
    case TIMEFRAME['365D']:
      endDate = endDate.endOf('month')

      if (isFirstDate) {
        return (
          <>
            <tspan x={xOffset} dy="0">
              {date.format('MMM. D')} - {endDate.format('D')},
            </tspan>
            <tspan x={xOffset} dy="1.2em">
              {endDate.format("'YY")}
            </tspan>
          </>
        )
      }

      if (isLastDate) {
        return (
          <>
            <tspan x={xOffset} dy="0">
              {date.format('MMM. D')} - {now.format('D')},
            </tspan>
            <tspan x={xOffset} dy="1.2em">
              {endDate.format("'YY")}
            </tspan>
          </>
        )
      }

      return <>{date.format("MMM. 'YY")}</>
    default:
      return <></>
  }
}

export const formatTooltipDate = (
  date: Moment,
  timeframe: TIMEFRAME
): string => {
  let endDate = moment(date).utc()
  const now = moment(moment.now()).utc()
  let doesMonthChange: boolean

  switch (timeframe) {
    case TIMEFRAME['24H']:
      return date.format('h:mm - h:59A z')
    case TIMEFRAME['7D']:
      return date.format('MMM. D')
    case TIMEFRAME['30D']:
      return date.format("MMM. D 'YY")
    case TIMEFRAME['90D']:
      // tooltip for 90 day timeframe should display 1 week range, i.e. Mar. 1 - 7, 8 - 14, etc.
      endDate = endDate.add(6, 'days')

      if (endDate.isSameOrAfter(now)) {
        endDate = now
      }

      doesMonthChange = date.month() !== endDate.month()

      return `${date.format('MMM. D')} - ${endDate.format(
        ` ${doesMonthChange ? 'MMM. D' : 'D'} 'YY`
      )}`
    case TIMEFRAME['180D']:
      // 180 day timeframe should display 2 week range
      endDate = endDate.add(13, 'days')

      if (endDate.isSameOrAfter(now)) {
        endDate = now
      }

      doesMonthChange = date.month() !== endDate.month()

      return `${date.format('MMM. D')} - ${endDate.format(
        ` ${doesMonthChange ? 'MMM. D' : 'D'} 'YY`
      )}`
    case TIMEFRAME['365D']:
      endDate = endDate.endOf('month')

      return date.format("MMM. 'YY")
    default:
      return ''
  }
}

export const renderTick = (
  tick: AxisTickProps<any>,
  value: ReactNode,
  axis: 'x' | 'y'
): JSX.Element => (
  <>
    <line
      x1={axis === 'x' ? tick.x : 0}
      x2={axis === 'x' ? tick.x : -5}
      y1={axis === 'x' ? 0 : tick.y}
      y2={axis === 'x' ? 5 : tick.y}
      strokeWidth="1"
      stroke={colorYonder}
    />
    <text
      x={axis === 'x' ? tick.x : tick.textX}
      y={axis === 'x' ? tick.textY : tick.y}
      dominantBaseline={axis === 'x' ? 'text-before-edge' : 'central'}
      textAnchor={axis === 'x' ? 'middle' : 'end'}
      fontSize={11}
    >
      {value}
    </text>
  </>
)

const SymbolAccept = ({ x, y }: { x: number; y: number }): JSX.Element => (
  <circle
    r={2.5}
    cx={x}
    cy={y}
    fill={colorWhite}
    strokeWidth={2}
    stroke={colorMeadow}
    data-test-id={`symbolAccept-${x}-${y}`}
  />
)

const SymbolReject = ({ x, y }: { x: number; y: number }): JSX.Element => (
  <circle
    r={3}
    cx={x}
    cy={y}
    fill={colorMagenta}
    data-test-id={`symbolReject-${x}-${y}`}
  />
)

const SymbolHold = ({ x, y }: { x: number; y: number }): JSX.Element => (
  <polygon
    points={`
      ${x},${y - 3}
      ${x + 3},${y}
      ${x},${y + 3}
      ${x - 3},${y}
    `}
    fill={colorWhite}
    strokeWidth={1.5}
    stroke={colorSlate}
    data-test-id={`symbolHold-${x}-${y}`}
  />
)

const SymbolCredit = ({ x, y }: { x: number; y: number }): JSX.Element => (
  <g transform={`translate(${x},${y})`}>
    <path
      d="M3 0 L6 6 L0 6 L3 0"
      fill={colorWhite}
      strokeWidth={1.5}
      stroke={colorGold}
      data-test-id={`symbolCredit-${x}-${y}`}
    />
  </g>
)

const SymbolNone = ({ x, y }: { x: number; y: number }): JSX.Element => (
  <circle
    r={3}
    cx={x + 3}
    cy={y + 3}
    fill={colorMarengo}
    data-test-id={`symbolNone-${x}-${y}`}
  />
)

export const getPointSymbol = (
  id: string,
  x: number,
  y: number
): JSX.Element => {
  switch (id) {
    case POLICY_REPORTING_LINE.accept:
      return <SymbolAccept x={x} y={y} />
    case POLICY_REPORTING_LINE.reject:
      return <SymbolReject x={x} y={y} />
    case POLICY_REPORTING_LINE.hold:
      return <SymbolHold x={x} y={y} />
    case POLICY_REPORTING_LINE.credit:
      return <SymbolCredit x={x} y={y} />
    default:
      return <SymbolNone x={x} y={y} />
  }
}

export const formatAxisNumber = (
  value: number,
  policyReportingType?: POLICY_REPORTING_TYPE,
  precision?: number
): string => {
  if (policyReportingType === POLICY_REPORTING_TYPE.gmv) {
    return `$${Intl.NumberFormat('en-US', { notation: 'compact' }).format(
      round(value, precision)
    )}`
  }

  return round(value, precision).toLocaleString('en', {
    useGrouping: true,
  })
}
