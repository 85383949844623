import { useUserContext } from '@signifyd/components'
import { RuleResponse } from '@signifyd/http'
import { Menu, MenuItemProps, Tooltip } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStoreActions } from 'stores'
import getPolicyDuplicationPayload from 'core/utils/policyDuplication/getPolicyDuplicationPayload'
import useGetRuleDuplicationAnalysis from 'core/queries/useGetRuleDuplicationAnalysis/useGetRuleDuplicationAnalysis'
import DuplicatePolicyModal from '../DuplicatePolicyModal/DuplicatePolicyModal'

interface Props {
  policy: RuleResponse
  menuKey: string
}

const DuplicatePolicyMenuItem: FC<Props> = ({ policy, menuKey, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { teams } = useUserContext()
  const { createPolicy } = useStoreActions((actions) => actions.ruleModel)
  const { data: duplicationAnalysis } = useGetRuleDuplicationAnalysis(
    policy.ruleId
  )

  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleDuplicateAndRedirect = (teamId: number): void => {
    createPolicy({
      policy: {
        ...getPolicyDuplicationPayload(policy),
        teamId,
      },
      navigate,
    })
  }

  const handleClick: MenuItemProps['onClick'] = ({ domEvent }): void => {
    if (teams.length > 1) {
      setIsModalVisible(true)

      return
    }

    domEvent.stopPropagation()

    handleDuplicateAndRedirect(policy.teamId)
  }

  const isDuplicationDisabled =
    duplicationAnalysis?.includesLegacyVariables || !policy.canModifyPolicy

  return (
    <>
      <Menu.Item
        {...props} // passing through antd props from menu
        key={menuKey}
        data-test-id="duplicatePolicyDetailsMenuItem"
        onClick={handleClick}
        disabled={isDuplicationDisabled}
        data-analytics-id="duplicate-policy-menu-item"
      >
        <Tooltip
          title={
            isDuplicationDisabled && t('ruleCommon.dropdown.duplicateTooltip')
          }
        >
          {t('ruleCommon.dropdown.duplicate')}
        </Tooltip>
      </Menu.Item>
      <DuplicatePolicyModal
        allowedTeamIds={
          duplicationAnalysis?.userTeamsAllowedForDuplication ?? []
        }
        isOpen={isModalVisible}
        policy={policy}
        onClose={() => setIsModalVisible(false)}
        onDuplicateAndRedirect={(teamId) => {
          handleDuplicateAndRedirect(teamId)
        }}
      />
    </>
  )
}

export default DuplicatePolicyMenuItem
