import { FC, FormEvent, useState } from 'react'
import { EXPIRATION_TYPES, PREDICTION_LIST_TYPE } from '@signifyd/http'
import { Form } from '@ant-design/compatible'
import { Button, Col, InputNumber, Row, Select, message } from 'antd'
import { Text } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import styles from './ListExpiration.less'
import { expirationTypesPerListType } from './listExpirationUtils'
import useListExpirationForm from './useListExpirationForm'
import ExpirationFormHeader from './ExpirationFormTooltip'
import DeleteExpirationModal from './DeleteExpirationModal'

export interface Props {
  predictionListId: number
  setVisible: (param: boolean) => void
  predictionListType: PREDICTION_LIST_TYPE
  currentExpiration: {
    value: number
    type: EXPIRATION_TYPES
  }
}

const EditListExpirationForm: FC<Props> = ({
  predictionListId,
  setVisible,
  predictionListType,
  currentExpiration,
}) => {
  const { t } = useTranslation()

  const { value, type } = currentExpiration

  const [expiration, setExpiration] = useState<number | null>(value)
  const [expirationType, setExpirationType] = useState(type)

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const { submitExpiration, deleteExpiration, updatePredictionListHTTPStatus } =
    useListExpirationForm({ predictionListId })

  const { pending } = updatePredictionListHTTPStatus

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault()

    if (!expiration) {
      return
    }

    try {
      await submitExpiration({ value: expiration, type: expirationType })
      message.success(t('listDetailsPage.sidePanel.updateExpirationSuccess'))

      setVisible(false)
    } catch {
      message.error(
        t(
          'listDetailsPage.sidePanel.expirationForm.errorMessage.updateExpiration'
        )
      )
    }
  }

  const handleDelete = async (): Promise<void> => {
    try {
      await deleteExpiration(expirationType)
      message.success(t('listDetailsPage.sidePanel.deleteExpirationSuccess'))
    } catch {
      message.error(
        t(
          'listDetailsPage.sidePanel.expirationForm.errorMessage.deleteExpiration'
        )
      )
    }
  }

  return (
    <>
      <ExpirationFormHeader
        formLabel={t('listDetailsPage.sidePanel.manageExpiration')}
        tooltipTitle={t('listDetailsPage.sidePanel.expirationFormTooltip')}
      />
      <Form onSubmit={handleSubmit} layout="vertical">
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <Text weight="semibold">
              {t(
                'listDetailsPage.sidePanel.expirationForm.formLabelExpireAfter',
                {
                  listType: t(
                    `listDetailsPage.sidePanel.expirationForm.listTypeTag.${predictionListType}`
                  ),
                }
              )}
            </Text>
          </Col>
          <Col span={8}>
            <InputNumber
              min={1}
              className={styles.expirationInput}
              required
              data-test-id="expirationInput"
              placeholder={t(
                'listDetailsPage.sidePanel.expirationForm.inputPlaceHolder'
              )}
              value={expiration}
              onChange={setExpiration}
            />
          </Col>

          <Col span={16}>
            <Select
              placeholder={t(
                'listDetailsPage.sidePanel.expirationForm.selectPlaceholder'
              )}
              onChange={setExpirationType}
              data-test-id="expirationType"
              value={expirationType}
            >
              {expirationTypesPerListType[predictionListType].map((unit) => (
                <Select.Option key={unit} value={unit}>
                  {t(
                    `listDetailsPage.sidePanel.expirationForm.expirationTypes.${unit}`,
                    {
                      count: expiration ?? 0,
                    }
                  )}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row gutter={[16, 8]} className={styles.buttonRow}>
          <Button
            className={styles.expirationFormButtons}
            data-test-id="editExpiration"
            block
            type="default"
            htmlType="submit"
            disabled={!expiration || !expirationType || pending}
            loading={!isDeleteModalVisible && pending}
          >
            {t('listDetailsPage.sidePanel.expirationForm.applyButton')}
          </Button>
        </Row>

        <Row gutter={[16, 8]} className={styles.buttonRow}>
          <Button
            className={styles.expirationFormButtons}
            data-test-id="deleteListExpiration"
            block
            danger
            onClick={() => setIsDeleteModalVisible(true)}
          >
            {t('listDetailsPage.sidePanel.expirationForm.deleteButton')}
          </Button>
        </Row>

        <Row gutter={[16, 8]}>
          <Button
            type="link"
            block
            className={styles.expirationFormButtons}
            onClick={() => setVisible(false)}
          >
            {t('listDetailsPage.sidePanel.cancelExpiration')}
          </Button>
        </Row>
      </Form>
      <DeleteExpirationModal
        visible={isDeleteModalVisible}
        setVisible={setIsDeleteModalVisible}
        loading={pending}
        onClick={handleDelete}
      />
    </>
  )
}

export default EditListExpirationForm
