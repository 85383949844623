import { useEffect, FC } from 'react'
import { Filters } from 'stores/predictionList'
import useUserTeams from 'core/hooks/useUserTeams'
import { useIsThirdGen } from '@signifyd/components'
import { getFiltersFromSearchParams } from './ListPage.utils'
import ListPageContainer from './containers/ListPageContainer'
import { useListPageQueryParams } from './useListPageQueryParams'
import ListPageContainerThirdGen from './containers/ListPageContainerThirdGen'

const useListPageFilters = (): Filters | null => {
  const { liveTeams } = useUserTeams()

  const [query, setQuery] = useListPageQueryParams()
  const filters = getFiltersFromSearchParams(query)

  const { teamId } = query
  const firstTeamId = liveTeams?.[0].teamId

  useEffect(() => {
    if (firstTeamId) {
      const selectedTeamId = teamId || firstTeamId

      setQuery({ teamId: selectedTeamId })
    }
  }, [teamId, setQuery, firstTeamId])

  return filters
}

const ListPage: FC = () => {
  const filters = useListPageFilters()

  const isThirdGen = useIsThirdGen()

  if (!filters?.teamId) {
    return null
  }

  return isThirdGen ? (
    <ListPageContainerThirdGen filters={filters} />
  ) : (
    <ListPageContainer filters={filters} />
  )
}

export default ListPage
