import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PageSpinner,
  Layout as SigLayout,
  useIsThirdGen,
} from '@signifyd/components'
import { Layout } from 'antd'
import AppNavBar, { ThirdGenNavBar } from 'core/containers/AppNavBar'
import { UnauthorizedLayout } from 'core/components/UnauthorizedLayout/UnauthorizedLayout'
import TestTeamBanner from 'core/components/TestTeamBanner'
import DashboardContainer from './containers/DashboardContainer'
import useSelectedTeamCheckpoints from './queries/useSelectedTeamCheckpoints'
import useTeamId from './hooks/useTeamId'
import useInitializeDashboard from './hooks/useInitializeDashboard'
import DashboardHeader from './components/DashboardHeader/DashboardHeader'
import DashboardContainerThirdGen from './containers/DashboardContainerThirdGen'

const { NavBar } = SigLayout

const { Content } = Layout

const DashboardPage: FC = () => {
  const { t } = useTranslation()

  const [createRuleModalVisible, setCreateRuleModalVisible] = useState(false)

  const teamId = useTeamId()

  const { data: selectedTeamCheckpoints, isLoading } =
    useSelectedTeamCheckpoints()

  useInitializeDashboard()

  const isThirdGen = useIsThirdGen()

  if (isLoading || !teamId) {
    return <PageSpinner />
  }

  const hasCheckpoints = selectedTeamCheckpoints?.length

  if (isThirdGen) {
    return (
      <Layout style={{ height: '100vh' }}>
        <ThirdGenNavBar />
        <Content>
          <TestTeamBanner
            teamId={teamId}
            text={t('common.testTeamBanner.policy')}
          />

          <DashboardHeader
            isCreatePolicyModalVisible={createRuleModalVisible}
          />

          {hasCheckpoints ? (
            <DashboardContainerThirdGen
              selectedTeamCheckpoints={selectedTeamCheckpoints}
              setCreateRuleModalVisible={setCreateRuleModalVisible}
            />
          ) : (
            <UnauthorizedLayout message={t('common.unauthorized.default')} />
          )}
        </Content>
      </Layout>
    )
  }

  return (
    <SigLayout>
      <NavBar>
        <AppNavBar teamId={Number(teamId)} />
      </NavBar>

      <TestTeamBanner
        teamId={teamId}
        text={t('common.testTeamBanner.policy')}
      />

      <DashboardHeader isCreatePolicyModalVisible={createRuleModalVisible} />

      {hasCheckpoints ? (
        <DashboardContainer
          selectedTeamCheckpoints={selectedTeamCheckpoints}
          setCreateRuleModalVisible={setCreateRuleModalVisible}
        />
      ) : (
        <UnauthorizedLayout message={t('common.unauthorized.default')} />
      )}
    </SigLayout>
  )
}

export default DashboardPage
