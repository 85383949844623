import { PREDICTION_LIST_ENTRY_SORT_BY } from '@signifyd/http'
import {
  DecodedValueMap,
  NumberParam,
  SetQuery,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { createEnumParam } from '@signifyd/utils'
import { GET_LIST_ENTRIES_LIMIT } from 'core/constants'

export const listDetailsPageQueryParams = {
  page: withDefault(NumberParam, 0),
  pageSize: withDefault(NumberParam, GET_LIST_ENTRIES_LIMIT),
  ascending: withDefault(StringParam, 'false'),
  orderBy: createEnumParam(
    PREDICTION_LIST_ENTRY_SORT_BY,
    PREDICTION_LIST_ENTRY_SORT_BY.SORT_CREATED_AT
  ),
  searchTerm: StringParam,
  isExpired: withDefault(StringParam, 'false'),
  isNewList: StringParam,
  teamId: NumberParam,
}

export type ListDetailsPageQueryParams = DecodedValueMap<
  typeof listDetailsPageQueryParams
>

export type UseListDetailsPageQueryParams = [
  ListDetailsPageQueryParams,
  SetQuery<typeof listDetailsPageQueryParams>,
]

export const useListDetailsPageQueryParams =
  (): UseListDetailsPageQueryParams => {
    const [query, setQuery] = useQueryParams(listDetailsPageQueryParams)

    return [query, setQuery]
  }
