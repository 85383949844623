import { FC, useEffect } from 'react'
import moment from 'moment-timezone'
import {
  AppAnalytics,
  AppConfigProvider,
  ShadowUserBanner,
  useAppCues,
  useUserContext,
  QAInfoPopover,
  PageSpinner,
  useThirdGenSplitIOContext,
  useIsThirdGen,
  ThirdGenToggle,
} from '@signifyd/components'
import { decisionCenterTokens } from '@signifyd/sig-keys'
import { legacyTheme, thirdGenTheme } from '@signifyd/ant'
import { Layout } from 'antd'
import useUserTeams from 'core/hooks/useUserTeams/useUserTeams'
import AppRoutes from './AppRoutes'

const AppContainer: FC = () => {
  const { user, userConfigs } = useUserContext()
  const { userTimeZone } = useUserTeams()

  useAppCues({
    user,
    appName: 'decision-center',
    allowedBuildEnvs: window.isRegressionTest ? [] : ['staging', 'production'],
    buildEnv: process.env.BUILD_ENV,
  })

  useEffect(() => {
    if (userTimeZone) {
      moment.tz.setDefault(userTimeZone)
    }
  }, [userTimeZone])

  // We don't want to render the UI until we know which UI version to show
  const { isLoading: isSplitIoLoading } = useThirdGenSplitIOContext()
  const isThirdGen = useIsThirdGen()

  const themeClassName = isThirdGen ? 'thirdGen' : 'secondGen'

  // Tooltips, popovers, and similar elements in ant are nested under a separate top level section under the body, this targets those too
  useEffect(() => {
    document.body.classList.add(themeClassName)

    return () => {
      document.body.classList.remove('thirdGen', 'secondGen')
    }
  }, [themeClassName])

  if (isSplitIoLoading) {
    return <PageSpinner />
  }

  return (
    <AppConfigProvider
      theme={isThirdGen ? thirdGenTheme : legacyTheme}
      localization={{ appName: 'decision-center' }}
      user={user}
      userConfigs={userConfigs}
    >
      <Layout>
        <AppAnalytics
          enabled={process.env.BUILD_ENV === 'production'}
          user={user}
          heapAppId={decisionCenterTokens.heapAppId}
          logRocketId={decisionCenterTokens.logRocketId}
        />
        <AppRoutes />
        <ShadowUserBanner currentUser={user} />
        <QAInfoPopover />
      </Layout>
      {!window.isRegressionTest && <ThirdGenToggle />}
    </AppConfigProvider>
  )
}

export default AppContainer
