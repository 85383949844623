import { action, actionOn, thunk } from 'easy-peasy'
import { AxiosError } from 'axios'
import { message } from 'antd'
import {
  createRule as createRuleHTTP,
  getDecisionCenterVariables,
  getRule as getRuleHTTP,
  listPredictionLists as listPredictionListsHTTP,
  updateRule as updateRuleHTTP,
  Variable,
  RuleResponse,
} from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'
import {
  getHTTPErrorStatus,
  messageOnError,
  START_STATUS,
  SUCCESS_STATUS,
} from 'core/http'
import { CATCH_ALL_PATH } from 'core/constants'
import {
  deserializePredicateToLegacyConditionTree,
  getRuleFeaturesByName,
  READONLY_RULE_FEATURES,
} from '../conditionTree'
import { RuleModel, RuleState } from './rule.types'
import { DEFAULT_STATE } from './rule.store.state'
import {
  DEFAULT_RULE_PROPS,
  getDefaultPredictionListMap,
} from './rule.store.constants'

export const getNewStateFromPolicyResponse = (
  policy: RuleResponse | null,
  policyFeatures: Array<Variable>,
  isEditing?: boolean
): RuleState | null => {
  try {
    if (!policy || !policyFeatures.length) {
      return null
    }

    const predicateToParse = isEditing
      ? policy.draftPredicate
      : policy.predicate

    const predicate = JSON.parse(predicateToParse || '{}')

    const policyFeaturesByName = getRuleFeaturesByName(policyFeatures)

    const conditionTree = deserializePredicateToLegacyConditionTree(
      predicate,
      policyFeaturesByName
    )

    const newState = {
      ...DEFAULT_STATE,
      policy,
      conditionTree,
      policyFeatures: policyFeatures.filter(
        ({ name }) => !READONLY_RULE_FEATURES.has(name)
      ),
      policyFeaturesByName,
    }

    return newState
  } catch {
    message.error(
      i18nInstance.t('ruleCommon.fallbackErrorMessage.deserializeRulePredicate')
    )

    return DEFAULT_STATE
  }
}

const getFilterVariables = async (
  policyResponse: RuleResponse,
  viewLegacyVariables?: boolean,
  isEditing?: boolean
): Promise<RuleState | null> => {
  try {
    const { checkpoint, teamId } = policyResponse

    const payload = {
      checkpoints: checkpoint,
      teamIds: teamId.toString(),
      isReadOnly: viewLegacyVariables,
      promoResellerTeamId: teamId,
    }

    const response = await getDecisionCenterVariables(payload)
    const filterData = response.data.data

    const sortFeatureByDescription = (a: Variable, b: Variable): number => {
      return a.description.localeCompare(b.description)
    }

    const sortedFilterVariables = [...filterData.sort(sortFeatureByDescription)]

    return getNewStateFromPolicyResponse(
      policyResponse,
      sortedFilterVariables,
      isEditing
    )
  } catch {
    message.error(
      i18nInstance.t('ruleCommon.fallbackErrorMessage.filterVariables')
    )

    return null
  }
}

const mergeStateAndActions = (state: RuleState): RuleModel => ({
  ...state,

  // common actions
  setState: action((state, newState) => {
    Object.assign(state, newState)
  }),

  resetState: action((state) => {
    Object.assign(state, DEFAULT_STATE)
  }),

  createPolicy: thunk((_actions, payload, { fail }) => {
    const { policy, navigate } = payload

    return createRuleHTTP({ ...DEFAULT_RULE_PROPS, ...policy })
      .then(({ data }) => {
        // Create endpoint doesn't return enriched policy, which
        // includes ruleStatus, so we have to call get rule to get all the props.
        // TODO FET-1823 https://signifyd.atlassian.net/browse/FET-1823 remove getRuleHTTP after BE is fixed
        const { ruleId } = data

        return getRuleHTTP(ruleId)
      })
      .then(({ data: ruleResponse }) => {
        navigate(`/policies/create/${ruleResponse.ruleId}/conditions`)
      })
      .catch(fail)
  }),

  getPolicy: thunk((actions, payload, { fail }) => {
    const { policy, navigate } = payload
    const { ruleId, viewLegacyVariables, isEditing } = policy

    if (!ruleId) {
      navigate(CATCH_ALL_PATH)

      return Promise.reject()
    }

    actions.resetState()

    return getRuleHTTP(ruleId)
      .then(async ({ data: policyResponse }) => {
        const newState = await getFilterVariables(
          policyResponse,
          viewLegacyVariables,
          isEditing
        )

        if (newState) {
          actions.setState(newState)
        }
      })
      .catch(() => {
        fail()
        navigate(CATCH_ALL_PATH)
      })
  }),

  updatePolicy: thunk((actions, payload) => {
    const { ruleId, ...rest } = payload

    return updateRuleHTTP(ruleId, rest)
      .then(({ data }) => {
        // TODO FET-1823 same as above comment - remove getRuleHTTP after BE is fixed
        const { ruleId } = data

        return getRuleHTTP(ruleId)
      })
      .then(({ data: policyResponse }) => {
        getFilterVariables(policyResponse).then((newState) => {
          if (newState) {
            actions.setState(newState)
          }
        })
      })
      .catch((e: AxiosError) => {
        throw e
      })
  }),

  updatePolicyDetails: thunk((actions, payload, { getState }) => {
    const { policy } = getState()
    if (!policy) {
      return Promise.reject()
    }

    return updateRuleHTTP(policy.ruleId, payload)
      .then(({ data: ruleResponse }) => {
        const updatedPolicy = {
          ...policy,
          name: ruleResponse.name,
          description: ruleResponse.description,
        }

        actions.setState({ policy: updatedPolicy })
      })
      .catch(
        messageOnError(
          i18nInstance.t('ruleCommon.fallbackErrorMessage.updateRule')
        )
      )
  }),

  listPredictionLists: thunk(async (actions, payload, { fail }) => {
    try {
      const {
        data: { data },
      } = await listPredictionListsHTTP({ ...payload, limit: 1000 })
      const predictionListMap = getDefaultPredictionListMap()

      data.forEach((predictionList) => {
        if (predictionList.type in predictionListMap) {
          predictionListMap[predictionList.type].push(predictionList)
        }
      })

      actions.setState({
        predictionListMap,
      })
    } catch (e) {
      fail(e)
    }
  }),

  onRuleHTTP: actionOn(
    (actions) => [
      actions.getPolicy.failType,
      actions.createPolicy.startType,
      actions.createPolicy.successType,
      actions.createPolicy.failType,
      actions.updatePolicy.startType,
      actions.updatePolicy.successType,
    ],
    (state, target) => {
      const [
        GET_RULE_FAILED,
        CREATE_RULE_START,
        CREATE_RULE_SUCCESS,
        CREATE_RULE_FAILED,
        UPDATE_RULE_START,
        UPDATE_RULE_SUCCESS,
      ] = target.resolvedTargets

      switch (target.type) {
        case GET_RULE_FAILED: {
          message.error({
            content: i18nInstance.t('ruleCommon.fallbackErrorMessage.getRule'),
            duration: 10,
          })
          break
        }
        case CREATE_RULE_START:
          state.policyHTTPStatuses.createPolicy = START_STATUS
          break
        case CREATE_RULE_SUCCESS:
          state.policyHTTPStatuses.createPolicy = SUCCESS_STATUS
          break
        case CREATE_RULE_FAILED: {
          const status = getHTTPErrorStatus(
            target.error as AxiosError,
            i18nInstance.t('ruleCommon.fallbackErrorMessage.createRule')
          )

          state.policyHTTPStatuses.createPolicy = status
          break
        }
        case UPDATE_RULE_START:
          state.policyHTTPStatuses.updatePolicy = START_STATUS
          break
        case UPDATE_RULE_SUCCESS:
          state.policyHTTPStatuses.updatePolicy = SUCCESS_STATUS
          break
        default:
          break
      }
    }
  ),

  setRuleRecommendedAction: action((state, recommendedAction) => {
    if (!state.policy) {
      return
    }

    state.policy.ruleOutcome.ruleRecommendedAction = recommendedAction
  }),

  onPredictionListsHTTP: actionOn(
    (actions) => [
      actions.listPredictionLists.startType,
      actions.listPredictionLists.successType,
      actions.listPredictionLists.failType,
    ],
    (state, target) => {
      const [
        GET_PREDICTION_LISTS_START,
        GET_PREDICTION_LISTS_SUCCESS,
        GET_PREDICTION_LISTS_FAILED,
      ] = target.resolvedTargets

      switch (target.type) {
        case GET_PREDICTION_LISTS_START:
          state.predictionListsHTTPStatuses.listPredictionLists = START_STATUS
          break
        case GET_PREDICTION_LISTS_SUCCESS:
          state.predictionListsHTTPStatuses.listPredictionLists = SUCCESS_STATUS
          break
        case GET_PREDICTION_LISTS_FAILED: {
          const status = getHTTPErrorStatus(
            target.error as AxiosError,
            i18nInstance.t(
              'ruleCommon.fallbackErrorMessage.listPredictionLists'
            )
          )

          state.predictionListsHTTPStatuses.listPredictionLists = status
          break
        }
        default:
          break
      }
    }
  ),
})

export default mergeStateAndActions
