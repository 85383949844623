import { FC } from 'react'
import { NavigationBar } from '@signifyd/components'
import {
  filterMenuItemByAuth,
  getNavigationItems,
} from '@signifyd/components/src/thirdGenComponents/NavigationBar/navigation.utils'
import useUserTeams from 'core/hooks/useUserTeams'
// import styles from './AppNavBar.less'

// Todo add back
// const items = (teamId?: number): Array<NavBarMenuItem> => {
//   const teamIdQueryParam = teamId ? `?teamId=${teamId}` : ''

//   return [
//     {
//       key: CUSTOMER_MENU_ITEM_KEY.DecisionCenter,
//       title: 'Decision Center',
//       name: 'Decision Center',
//       subItems: [
//         {
//           key: CUSTOMER_MENU_ITEM_KEY.DecisionCenterRules,
//           title: 'Policies',
//           name: 'Policies',
//           OverrideComponent: (
//             <Link
//               className={styles.navLink}
//               to={`/policies/dashboard${teamIdQueryParam}`}
//             >
//               {i18nInstance.t('common.routes.policies')}
//             </Link>
//           ),
//         },
//         {
//           key: CUSTOMER_MENU_ITEM_KEY.DecisionCenterLists,
//           title: 'Lists',
//           name: 'Lists',
//           OverrideComponent: (
//             <Link className={styles.navLink} to={`/lists${teamIdQueryParam}`}>
//               {i18nInstance.t('common.routes.lists')}
//             </Link>
//           ),
//         },
//       ],
//     },
//   ]
// }

interface Props {
  teamId?: number
}

const ThirdGenNavBar: FC<Props> = () => {
  const { updateUiState, user, userConfigs } = useUserTeams()

  if (!user || !userConfigs) {
    return null
  }

  const userRolesSet = new Set(user.roles)

  const items = filterMenuItemByAuth(getNavigationItems(), userRolesSet, user)

  return (
    <NavigationBar
      items={items}
      user={user}
      userConfigs={userConfigs}
      onLanguageSelectionChange={(selectedLocale) => {
        updateUiState({ locale: selectedLocale })
      }}
    />
  )
}

export default ThirdGenNavBar
