import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Layout } from 'antd'
import { Space } from '@signifyd/components'
import ListDetailsPageSidePanel from 'pages/ListDetailsPage/containers/ListDetailsPageSidePanel'
import usePredictionListDetails from 'core/hooks/usePredictionListDetails'
import TestTeamBanner from 'core/components/TestTeamBanner'
import { ThirdGenNavBar } from 'core/containers/AppNavBar'
import ListDetailsPageHeader from './containers/ListDetailsPageComponents/ListDetailsPageHeader'
import ListDetailsPageTitle from './containers/ListDetailsPageComponents/ListDetailsPageTitle'
import ListDetailsPageOrdersToggle from './containers/ListDetailsPageComponents/ListDetailsPageOrdersToggle'
import ListEntriesTableContainer from './containers/ListEntriesTableContainer/ListEntriesTableContainer'
import styles from './ListDetailsPageThirdGen.less'

const { Content } = Layout

const ListDetailsPageThirdGen: FC = () => {
  const { listId } = useParams()
  const { data: predictionList } = usePredictionListDetails(Number(listId))

  if (!listId || !predictionList) {
    return null
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <ThirdGenNavBar />
      <TestTeamBanner teamId={predictionList.teamId} />

      <Content className={styles.content}>
        <ListDetailsPageHeader predictionList={predictionList} />

        <Row justify="space-between">
          <ListDetailsPageTitle predictionList={predictionList} />
          <ListDetailsPageOrdersToggle predictionList={predictionList} />
        </Row>

        <Space size="md" />

        <Row gutter={32}>
          <Col span={18}>
            <ListEntriesTableContainer predictionList={predictionList} />
          </Col>
          <Col span={6}>
            <ListDetailsPageSidePanel predictionList={predictionList} />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default ListDetailsPageThirdGen
